import React, { Component } from "react"
import { Link } from "gatsby"
import Layout from '../components/layout'
import GetInTouch from '../components/get-in-touch'
import trtData from '../content/trt-data.json'
import Loadable from '@loadable/component';
import Metatags from '../components/Metatags';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
const OwlCarousel = Loadable(() => import('react-owl-carousel'));

import { gsap, TimelineMax } from 'gsap/all';

class AboutUSPage extends React.Component {

    componentWillMount() {
        gsap.registerPlugin(TimelineMax);
    }

    componentDidMount() {
        this.layerAnimation()
    }

    layerAnimation() {
        const animationConfig = {
            x: -200,
            opacity: 0,
        };
        const imageAnimation = new TimelineMax({
            repeat: -1,
            repeatDelay: 3,
        });

        imageAnimation
            .from("#animateImg1", 1, {
                ...animationConfig,
                // onStart: () => this.animationStart(1),
            })
            .to("#animateLine", 1, {
                webkitClipPath: 'polygon(0 0, 72% 0, 0 56%, 0 0)',
            })
            .from("#animateImg2", 1, {
                ...animationConfig,
                // onStart: () => this.animationStart(2),
            })
            .to("#animateLine", 1, {
                webkitClipPath: 'polygon(0 0, 135% 0, 0 56%, 0 0)',
            })
            .from("#animateImg3", 1, {
                ...animationConfig,
                // onStart: () => this.animationStart(3),
            })
            .to("#animateLine", 1, {
                webkitClipPath: 'polygon(0 0, 100% 0, 66% 82%, 0 0)',
            })
            .from("#animateImg4", 1, {
                ...animationConfig,
                // onStart: () => this.animationStart(4),
            })
            .to("#animateLine", 1, {
                webkitClipPath: 'polygon(0 0, 100% 0, 29% 170%, 0 0)',
            })
            .from("#animateImg5", 1, {
                ...animationConfig,
                // onStart: () => this.animationStart(5),
            });
    }

    OurDesignProcessSlider(image, imgAlt, title, description, i) {
        return (
            <div className="item" key={i}>
                <div className="ourDesignProcess-item">
                    <div className="row align-items-end">
                        <div className="col-sm-6">
                            <div className="design-process-box">
                                <h3>{title}</h3>
                                <p>{description}</p>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="design-process-img">
                                <img className="w-100" src={image} alt={imgAlt} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        return (
            <Layout>
                <section className="trt-about-banner">
                    <div className="container">
                        <div className="row justify-content-between align-items-center">
                            <div className="col-lg-7 order-1 order-lg-0">
                                <h1 className="d-none d-sm-block">Ons verhaal</h1>
                                <p>Eigenaar en ondernemer Nicky de Swart is zelf een creatieve techneut. Altijd bezig met de nieuwste technieken kocht hij samen met zijn vader en broer voor hun constructiebedrijf een buislaser. In afwachting van de machine realiseerden zij zich één ding: de nieuwe machine kon niet werken met de verouderde bibliotheek en instellingen.</p>
                                <p>Na veel, en veel te dure uitbestedingen in Nederland en het aannemen van meerdere tekenaars werd per toeval een link gelegd met een engineer uit Vietnam: Nguyễn Duy Quang. De eerste contacten verliepen nog wat stroef. Het overbrengen van een idee in een andere taal en elkaar leren begrijpen had tijd nodig.</p>
                                <p>Na jaren van intensief contact, een aantal ontmoetingen en een goede vriendschap, is de samenwerking ijzersterk. Zo is IJzersterk Tekenwerk een bedrijf geworden wat zich kan meten met de allerbeste Nederlandse engineers. Dat tegen een fractie van de prijs. Met behulp van IJzersterk Tekenwerk zijn er al meer dan 1000 succesvolle Nederlandse projecten afgerond. Iets waar wij enorm trots op zijn.</p>
                                <Link className="btn btn-trt-primary" to="/portfolio">Portfolio</Link>
                            </div>
                            <div className="col-lg-5 text-center">
                                <h1 className="d-block d-sm-none">Ons verhaal</h1>
                                <img className="w-75"
                                    src="../images/about-us-artwork.png"
                                    alt="" />
                            </div>
                        </div>
                    </div>
                </section>

                <section className="experts">
                    <div className="container">
                        <h3>Jouw werk in handen van een expert:</h3>
                        <div className="experts-details">
                            <div className="experts-name">
                                <img src="../images/krunal-icon.png"
                                    alt="" />
                                <div>
                                    <h5>Nicky de Swart</h5>
                                    <p className="m-0">Eigenaar en oprichter</p>
                                </div>
                            </div>
                            <p>Nicky heeft meer dan 15 jaar ervaring in 2D en 3D tekenwerk. Hij kent alle ins en outs van het werk en kan waar nodig adviseren en ondersteunen. Naast de vakkennis die hij bezit weet hij haarfijn aan te voelen wat zijn klanten willen en hoe hij zijn engineers het beste in kan zetten.</p>
                        </div>

                        <div className="experts-details">
                            <div className="experts-name">
                                <img src="../images/tapan-icon.png"
                                    alt="" />
                                <div>
                                    <h5>Nguyễn Duy Quang</h5>
                                    <p className="m-0">Partner en management Vietnam</p>
                                </div>
                            </div>
                            <p>Quang heeft gedurende zijn hele loopbaan als engineer voor de westerse markt gewerkt. Afgestudeerd als Mechanical Engineer is hij begonnen in een team van 25 personen. Al snel werd Quang teamleider waarna hij Nicky leerde kennen. Samen zijn ze, op bijna 10.000 km afstand van elkaar, begonnen aan een nieuw avontuur. Nicky en Quang startten samen een eigen kantoor waar inmiddels tien mensen werkzaam zijn. Als aanspreekpunt weet Quang als geen ander prioriteiten te stellen en met zijn team zorgt hij ervoor dat jouw deadlines worden gehaald.</p>
                        </div>
                    </div>
                </section>

                <section className="employee-details">
                    <div className="container">
                        <h3>Dit is ons team</h3>
                        <div className="row justify-content-center">
                            <div className="col-sm-4">
                                <div className="row justify-content-center">
                                    <div className="col-sm-6 mb-4">
                                        <div className="employee">
                                            <div className="employee-img emp-9"></div>
                                            <p>Đong Huu Loi</p>
                                            <p>Manager</p>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 mb-4">
                                        <div className="employee">
                                            <div className="employee-img emp-4"></div>
                                            <p>Dam Thanh Cong Hau</p>
                                            <p>Project Manager</p>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 mb-4">
                                        <div className="employee">
                                            <div className="employee-img emp-7"></div>
                                            <p>Nguyen Huu Loc</p>
                                            <p>Project Manager</p>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 mb-4">
                                        <div className="employee">
                                            <div className="employee-img emp-3"></div>
                                            <p>Vo Nhat Tu</p>
                                            <p>Technical Engineer</p>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 mb-4">
                                        <div className="employee">
                                            <div className="employee-img emp-5"></div>
                                            <p>Pham Vu Hoai Phong</p>
                                            <p>Technical Engineer</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-4 d-flex align-self-start mobile-top">
                                <div className="employee partner">
                                    <img className="w-100 mb-2" src="../images/employee/partner.png" alt="" />
                                    <p>Nguyen Duy Quang</p>
                                    <p>Partner and General Manager</p>
                                </div>
                                <div className="employee partner">
                                    <img className="w-100 mb-2" src="../images/employee/partner-2.png" alt="" />
                                    <p>Nicky de Swart</p>
                                    <p>Partner</p>
                                </div>
                            </div>
                            <div className="col-sm-4">
                                <div className="row justify-content-center">
                                    <div className="col-sm-6 mb-4">
                                        <div className="employee">
                                            <div className="employee-img emp-1"></div>
                                            <p>Tran Ngoc Thien An</p>
                                            <p>Technical Engineer</p>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 mb-4">
                                        <div className="employee">
                                            <div className="employee-img emp-2"></div>
                                            <p>Huynh Tin</p>
                                            <p>Technical Engineer</p>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 mb-4">
                                        <div className="employee">
                                            <div className="employee-img emp-6"></div>
                                            <p>Huynh Tuan Dat</p>
                                            <p>Technical Engineer</p>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 mb-4">
                                        <div className="employee">
                                            <div className="employee-img emp-8"></div>
                                            <p>Trinh Quoc Oai</p>
                                            <p>Technical Engineer</p>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 mb-4">
                                        <div className="employee">
                                            <div className="employee-img emp-10"></div>
                                            <p>Truong Giang</p>
                                            <p>Technical Engineer</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <div className="bg-gradient">
                    <section className="why-us">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-8">
                                    <h3>Waarom IJzersterk Tekenwerk?</h3>
                                    <p className="d-none d-sm-block">Wij hebben een passie voor engineering, techniek en creativiteit. Communiceren is voor ons even belangrijk als de dienst zelf. Bij IJzersterk Tekenwerk is het altijd duidelijk wat we gaan doen en wat dat gaat kosten. Zo heb je als ondernemer of als werkvoorbereider vooraf inzicht in plaats van achteraf!</p>
                                    <p className="d-none d-sm-block">Wist je dat wij op basis van “no cure, no pay” werken? Wij gaan altijd door tot jij tevreden bent en pas daarna wordt er gefactureerd. Ook gaan wij NOOIT boven budget! Helder toch?</p>
                                </div>
                            </div>
                            <div className="row align-items-center">
                                <div className="col-lg-4">
                                    <div className="text-center d-block d-sm-none mb-3">
                                        <img className="w-75"
                                            src="../images/why-us.png"
                                            alt="" />
                                    </div>
                                    <ul>
                                        <li>1. Wij stellen doelen en zijn pas tevreden als deze behaald zijn.</li>
                                        <li>2. We zijn bekend met de allernieuwste technieken</li>
                                        <li>3. We ontlasten de ondernemer en werkvoorbereider om samen te kunnen groeien</li>
                                        <li>4. Door onze diensten uit te besteden aan ons tekenbureau in Vietnam kunnen we een stuk voordeliger werken dan andere aanbieders</li>
                                        <li>5. Er zijn flexibele modellen om ons in te huren</li>
                                        <li>6. Ons team is toegewijd, professioneel en gepassioneerd</li>
                                    </ul>
                                </div>
                                <div className="col-lg-8 d-none d-sm-block">
                                    <img className="w-100"
                                        src="../images/why-us.png"
                                        alt="" />
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="our-milestones">
                        <div className="container">
                            <h3>Onze Milestones</h3>
                            <div className="milestones-tabs">
                                <div className="row">
                                    <div className="col-lg-5 mb-5 mb-lg-0">
                                        <ul className="nav nav-pills"
                                            role="tablist">
                                            <li className="nav-item">
                                                <a className="nav-link"
                                                    data-toggle="pill">
                                                    <span>2016</span>
                                                    <div>
                                                        <img src="../images/milestone/milestone-icon-1.png"
                                                            alt="" />
                                                        <span>Opgericht</span>
                                                    </div>
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link"
                                                    data-toggle="pill">
                                                    <span>2017</span>
                                                    <div>
                                                        <img src="../images/milestone/milestone-icon-2.png"
                                                            alt="" />
                                                        <span>Onze eerste werknemers zijn een feit. Nicky en Quang hebben hun eerste echte ontmoeting in HCMC</span>
                                                    </div>
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link"
                                                    data-toggle="pill">
                                                    <span>2018</span>
                                                    <div>
                                                        <img src="../images/milestone/milestone-icon-3.png"
                                                            alt="" />
                                                        <span>Team groeit gestaag. Steeds meer klanten weten hun weg naar ons te vinden</span>
                                                    </div>
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link"
                                                    data-toggle="pill">
                                                    <span>2019</span>
                                                    <div>
                                                        <img src="../images/milestone/milestone-icon-4.png"
                                                            alt="" />
                                                        <span>Eerste klanten outsourcen hun volledige tekenkamer</span>
                                                    </div>
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link"
                                                    data-toggle="pill">
                                                    <span>2020</span>
                                                    <div>
                                                        <img src="../images/milestone/milestone-icon-5.png"
                                                            alt="" />
                                                        <span>1000ste opdracht is een feit met inmiddels onze tiende medewerker</span>
                                                    </div>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-lg-7 d-none d-sm-block">
                                        <div className="milestone-line" id="animateLine"></div>
                                        <div className="tab-content-animate">
                                            <img id="animateImg1" src="../images/milestone/Milestone-2016.png" alt="" />
                                            <img id="animateImg2" src="../images/milestone/Milestone-2017.png" alt="" />
                                            <img id="animateImg3" src="../images/milestone/Milestone-2018.png" alt="" />
                                            <img id="animateImg4" src="../images/milestone/Milestone-2019.png" alt="" />
                                            <img id="animateImg5" src="../images/milestone/Milestone-2020.png" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div >
                        </div >
                    </section >

                    <section className="how-we-function">
                        <div className="container">
                            <h3>Hoe we werken:</h3>

                            <div className="row align-items-center">
                                {/* <div className="col-sm-4 mb-5 mb-sm-0">
                                    <img className="w-100"
                                        src="../images/how-we-function.gif"
                                        alt="" />
                                </div> */}
                                <div className="col-sm-12">
                                    <div className="functions">
                                        <h5>KENNISMAKING</h5>
                                        <p>We beginnen met een informele kennismaking. Wat zijn de behoeften en hoe is de engineering nu geregeld? Waar liggen de raakvlakken? Wat kunnen wij voor jou betekenen?</p>
                                    </div>
                                    <div className="functions">
                                        <h5>PLAN</h5>
                                        <p>We maken een plan. Een tekenstandaard kan hierbij helpen. Is het een los project of kunnen we ook op de langere termijn een rol spelen?</p>
                                    </div>
                                    <div className="functions">
                                        <h5>VOORSTEL</h5>
                                        <p>We maken een voorstel over hoe wij denken dat onze samenwerking het efficiëntst zou zijn. Hoe dit er uit ziet is per klant verschillend. De kosten worden inzichtelijk en jij weet als klant meteen waar je aan toe bent.</p>
                                    </div>
                                    <div className="functions">
                                        <h5>START</h5>
                                        <p>Tijdens de start van een project houden we klanten voortdurend op de hoogte. Tijdsplanningen worden strak gemonitord. Vooraf afgesproken deadlines komen wij altijd na.</p>
                                    </div>
                                    <div className="functions">
                                        <h5>OPLEVERING</h5>
                                        <p>Voor zover hiervan sprake is volgt op dit punt de oplevering. In sommige gevallen is onze service namelijk een doorlopend proces. Wij luisteren altijd naar onze klanten. Feedback is tijdens, maar zeker ook na oplevering van een project heel erg belangrijk en waardevol voor ons. Zo kunnen wij bij een volgende samenwerking nog beter op jouw wensen inspelen.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="our-development-process">
                        <div className="container">
                            <h3>Our Development Process</h3>
                            <div className="row justify-content-center">
                                <div className="col-sm-6 col-lg-3 mb-3">
                                    <div className="dev-process">
                                        <h5>AutoCAD 2d</h5>
                                        <img src="../images/source-control-icon.png"
                                            alt="" />
                                        <p>In sommige gevallen kan een simpel grondplan, schets of idee snel en eenvoudig uitgewerkt worden in AutoCAD. Voor sommige klanten volstaat zelfs een AutoCAD tekening voor in de werkplaats.</p>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-lg-3 mb-3">
                                    <div className="dev-process">
                                        <h5>Inventor</h5>
                                        <img src="../images/source-control-icon.png"
                                            alt="" />
                                        <p>Een groot gedeelte van ons werk wordt gedaan met Autodesk Inventor. Wanneer er eenmaal een bibliotheek van onderdelen is opgebouwd wordt het construeren van een nieuw product heel eenvoudig. Het exporteren van stuklijsten op assemblage- of artikelniveau naar een ERP-programma is eenvoudig te realiseren. Er kunnen producttekeningen voor de eigen werkplaats worden gemaakt, of juist om uit te besteden. De klant bepaalt de output.</p>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-lg-3 mb-3">
                                    <div className="dev-process">
                                        <h5>Solidworks</h5>
                                        <img src="../images/source-control-icon.png"
                                            alt="" />
                                        <p>Wanneer jouw bedrijf is ingericht op Solidworks, kunnen wij ook hiermee werken. De invulling hiervan gebeurt op een soortgelijke manier als wanneer wij met Inventor zouden werken.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <GetInTouch className="d-block d-sm-none" />
                </div >
            </Layout >
        )
    }
}

export default AboutUSPage;